import React from 'react'

import Link from '../components/link'
import { classes } from '../util/components'
import styles from './center-text-section.module.css'
import Image from './image'

const CenterTextSection = ({
  title,
  image,
  body,
  bodyHtml,
  callToAction,
  className,
}) => {
  return (
    <div className={`${className} w-full text-center`}>
      <Image
        className={`${styles.sectionImage}`}
        fluid={image && image.childImageSharp.fluid}
        alt={title}
        title={title}
      />
      <div
        className={`${styles.sectionContent} w-full text-center md:w-4/5 lg:w-3/5 mx-auto`}
      >
        <h1 className={styles.sectionTitle}>{title}</h1>
        {body ? (
          <p className={`${styles.description} ${styles.sectionBody}`}>
            {body}
          </p>
        ) : (
          ''
        )}
        {bodyHtml ? (
          <div
            className={classes(styles.description, styles.sectionBody)}
            dangerouslySetInnerHTML={{ __html: bodyHtml }}
          />
        ) : (
          ''
        )}
        {callToAction && callToAction.linkURL ? (
          callToAction.linkURL.startsWith('/') ? (
            <Link
              className={classes(styles.callToAction, 'btn-primary')}
              to={callToAction.linkURL}
            >
              {callToAction.label || 'Find Out More'}
            </Link>
          ) : (
            <a
              className={classes(styles.callToAction, 'btn-primary')}
              href={callToAction.linkURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {callToAction.label || 'Find Out More'}
            </a>
          )
        ) : null}
      </div>
    </div>
  )
}

export default CenterTextSection
