import { globalHistory } from '@reach/router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Link from '../components/link'
import { classes } from '../util/components'
import Image from './image'
import styles from './promotions.module.css'

const Promotions = ({
  promotions,
  alternateStyling,
  firstTextLeft,
  className,
}) => {
  const [openedModalIndex, setOpenedModalIndex] = useState(-1)

  return promotions.map((p, i) => (
    <div
      key={i}
      className={classes(
        styles.promotionsWrapper,
        i % 2 === (firstTextLeft ? 0 : 1) &&
          alternateStyling &&
          styles.promotionsWrapperRight,
        className
      )}
    >
      <div className="flex flex-col md:flex-row items-center">
        <div className={`flex-1 ${styles.coverWrapper}`}>
          <div className={styles.coverImage}>
            <Image image={p.coverImage} alt={p.title}></Image>
          </div>
        </div>
        <div className={`flex-1 ${styles.detailsWrapper}`}>
          {p.title ? (
            <h2 className={`text-white ${styles.detailsTitle}`}>{p.title}</h2>
          ) : (
            ''
          )}
          {p.blurb ? (
            <p className={`text-white ${styles.detailsBlurb}`}>{p.blurb}</p>
          ) : (
            ''
          )}
          {p.bodyHtml ? (
            <div
              className={`text-white ${styles.detailsBlurb}`}
              dangerouslySetInnerHTML={{ __html: p.bodyHtml }}
            />
          ) : (
            ''
          )}
          {p.children ? p.children : ''}
          {p.callToAction ? (
            p.modal ? (
              <>
                {p.linkURL && p.linkURL.startsWith('#')
                  ? p.modal({
                      open: globalHistory.location.hash === p.linkURL,
                      handleClose: () => (window.location = '#'),
                    })
                  : p.modal({
                      open: openedModalIndex === i,
                      handleClose: () => setOpenedModalIndex(-1),
                    })}
                {p.linkURL && p.linkURL.startsWith('#') ? (
                  <button
                    className="btn btn-primary inline-block"
                    onClick={() => (window.location = p.linkURL)}
                  >
                    {p.callToAction}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary inline-block"
                    onClick={() => setOpenedModalIndex(i)}
                  >
                    {p.callToAction}
                  </button>
                )}
              </>
            ) : (
              <Link className="btn btn-primary inline-block" to={p.linkURL}>
                {p.callToAction}
              </Link>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  ))
}

Promotions.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.object),
  alternateStyling: PropTypes.bool,
  className: PropTypes.string,
}

export default Promotions
